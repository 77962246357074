define('ember-cli-postcss/services/custom-properties', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var docEl = window && window.document ? window.document.documentElement : null;

  exports.default = Ember.Service.extend({
    getVal: function getVal(_ref) {
      var _ref$element = _ref.element,
          element = _ref$element === undefined ? docEl : _ref$element,
          variableName = _ref.variableName;

      if (!element) {
        Ember.debug('No element provided');
        return false;
      }

      if (cssVariablesSupported()) {
        if (variableName) {
          return getComputedStyle(element).getPropertyValue(variableName).trim();
        } else {
          Ember.debug('No variable name provided');
        }
      } else {
        Ember.debug('CSS variables are not supported');
        Ember.debug('Tried to get custom property on <' + element + '> with name ' + variableName);
      }
    },
    setVal: function setVal(_ref2) {
      var _ref2$element = _ref2.element,
          element = _ref2$element === undefined ? docEl : _ref2$element,
          variableName = _ref2.variableName,
          variableValue = _ref2.variableValue;

      if (!element) {
        Ember.debug('No element provided');
        return false;
      }

      if (cssVariablesSupported()) {
        if (!!variableName && !!variableValue) {
          return element.style.setProperty(variableName, variableValue);
        } else {
          Ember.debug('Needs variable name and value to perform setVal');
        }
      } else {
        Ember.debug('CSS variables are not supported');
        Ember.debug('Tried to set custom property on <' + element + '> with name ' + variableName + ' and value ' + variableValue);
      }
    },
    removeVal: function removeVal(_ref3) {
      var _ref3$element = _ref3.element,
          element = _ref3$element === undefined ? docEl : _ref3$element,
          variableName = _ref3.variableName;

      if (!element) {
        Ember.debug('No element provided');
        return false;
      }

      if (cssVariablesSupported()) {
        if (variableName) {
          return element.style.removeProperty(variableName);
        } else {
          Ember.debug('No variable name provided');
        }
      } else {
        Ember.debug('CSS variables are not supported');
        Ember.debug('Tried to remove custom property on <' + element + '> with name ' + variableName);
      }
    },
    supported: function supported() {
      return cssVariablesSupported();
    }
  });


  function cssVariablesSupported() {
    // Relies on the CSS suports API in the browser
    return window && window.CSS && window.CSS.supports && window.CSS.supports('--test-var', 0);
  }
});